<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card>


        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
          <b-col md=12>

            <div class="demo-inline-spacing float-right ">
              
                <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  :to="{ name: 'safety-inspection-questions'}"
                >
                <feather-icon
                    icon="TargetIcon"
                    class="mr-25"
                  />
                  <span>Questions</span>
                </b-badge>
              </b-button>
            </div>
          </b-col>
        </b-row>
        
        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left"> Quarterly Safety Inspection</h4>
              
          </b-col>
          
        </b-row>

        <b-row>

          <b-col md="4">
              
              <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="form.site" @change="getQuestions()">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>
          <b-col md="4">
              
              <b-form-group
                  label="Conducted By"
                  class="required"

                >
                  <b-form-input 
                    autocomplete="off"
                    placeholder="3 to 50 characters"
                    v-model="form.conducted_by"
                  />
                    
                </b-form-group>
              
          </b-col>
          <b-col md="4">
              
              <b-form-group
                  label="Designation"
                  class="required"

                >
                  <b-form-input 
                    autocomplete="off"
                    placeholder="3 to 50 characters"
                    v-model="form.designation"
                  />
                    
                </b-form-group>
              
          </b-col>
          
        </b-row> 
    
      </b-card>
      <b-card>
        <b-form @submit="formSubmit">

        <!-- <b-row>

            <b-col md="1">

              <h5>No.</h5>

            </b-col>

            <b-col md="5">

              <h5>Storage 仓库</h5>

            </b-col>

            <b-col md="1">

              <h5>Yes</h5>

            </b-col>
            <b-col md="1">

              <h5>No</h5>

            </b-col>
            <b-col md="1">

              <h5>N/A</h5>

            </b-col>
            <b-col md="3">

              <h5 >Remarks</h5>

            </b-col>
        </b-row>
        <b-row>

            <b-col md="12">

              <h5 class="text-center mt-1">Chemical Store 化学制品 / Machine Store 机器</h5>

            </b-col>

            
        </b-row>
        <hr>
        <div v-if="questions.length == 0">
          <b-row>

            <b-col md="12">

              <p class="mt-1">No Questions Found</p>
            </b-col>
          </b-row>
        </div>
        <div v-if="questions.length > 0">
          <b-row v-for="(q,index) in questions" :key="index">

              <b-col md="1">

                <p>{{index +1 }}</p>

              </b-col>

              <b-col md="5">

                <p>{{q.en_question}}</p>
                <p v-if="q.zh_question != '' ">{{q.zh_question}}</p>

              </b-col>

              <b-col md="1">

                <b-form-checkbox
                  value="yes"
                  class="custom-control-warning"
                  v-model="q.pass"
                  @change="updateCheckbox($event,index,'pass')"
                />

              </b-col>
              <b-col md="1">

                <b-form-checkbox
                  value="yes"
                  class="custom-control-danger"
                  v-model="q.fail"
                  @change="updateCheckbox($event,index,'fail')"
                />

              </b-col>
              <b-col md="1">

                <b-form-checkbox
                  value="yes"
                  class="custom-control-secondary"
                  v-model="q.na"
                  @change="updateCheckbox($event,index,'na')"
                />

              </b-col>
              <b-col md="3">

                  <b-form-input 
                    autocomplete="off"
                    v-model="q.remarks"
                    placeholder="upto 50 characters"
                    
                  />
                        
              </b-col>
          </b-row>
        </div> -->

        <div class="fmdlTable table-responsive" style="margin-bottom: 0px !important;">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" style="width: 4%;" class="text-center">#</th>
                        <th scope="col" style="width: 41%;">Storage 仓库</th>
                        <th scope="col" style="width: 10%;" class="text-center">Yes</th>
                        <th scope="col" style="width: 10%;" class="text-center">No</th>
                        <th scope="col" style="width: 10%;" class="text-center">N/A</th>
                        <th scope="col" style="width: 25%;">Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="6" class="text-center py-2">
                          <span class="text-center text-white h5">Chemical Store 化学制品 / Machine Store 机器</span>
                        </td>
                    </tr>
                    <tr v-for="(q,index) in questions" :key="index" v-if="questions.length > 0">
                        <td class="text-center"> {{index + 1 }} </td>
                        <td>
                            <span>
                                <span>{{q.en_question}}</span>
                                <span v-if="q.zh_question != '' ">{{q.zh_question}}</span>
                            </span>
                        </td>
                        <td>
                            <b-form-checkbox
                                value="yes"
                                class="custom-control-warning ml-2"
                                v-model="q.pass"
                                @change="updateCheckbox($event,index,'pass')"
                            />
                        </td>
                        <td>
                            <b-form-checkbox
                                value="yes"
                                class="custom-control-danger ml-2"
                                v-model="q.fail"
                                @change="updateCheckbox($event,index,'fail')"
                            />
                        </td>
                        <td>
                            <b-form-checkbox
                                value="yes"
                                class="custom-control-secondary ml-2"
                                v-model="q.na"
                                @change="updateCheckbox($event,index,'na')"
                            />
                        </td>
                        <td>
                            <b-form-input 
                                autocomplete="off"
                                v-model="q.remarks"
                                placeholder="upto 50 characters"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <hr>
        <!-- side by side sign module  -->
        <b-row>
          <b-col md="6" class="">
            <h4 class="mb-0 text-center align_start_mobile"> Signature (Conducted By) <span style="color:red">*</span></h4>

            <div class="text-center  full_height_width_img_mobile align_start_mobile">

              <b-media class="mb-1 mt-2 text-center align_start_mobile" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                <template #aside>
                  <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded class="text-center"/>
                </template>

                <div class="d-flex flex-wrap">
                  <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                </div>
              </b-media>

            </div>

            <div class="text-center sign_image_top_mrgn full_height_width_img_mobile">

              <img :src="supervisorSign.image" width="200px" height="180" class="rounded mb-1 mt-2" v-if="supervisorSign.image != null" style="background: #ffffff">
              <br>
              <b-button variant="danger" class="mt-1 ml-2 btn_danger_mobile_left_mrgn_zero text-center under_construct_mobile_add_more_btn" v-if="supervisorSign.image != null" @click="removeSign('supervisor')" >
                <feather-icon icon="XIcon" />
              </b-button>

            </div>
          </b-col>

          <b-col md="6 two_signature_img">
            <!-- Signature component for Validated By User -->
            <SignatureComponent v-bind:title="'Signature (Validated By)'"/>
          </b-col>
        </b-row>


        <!-- <b-row>

            <b-col
              cols="5"
              class="mb-2 mt-2"
            > 
            </b-col>
            <b-col
              cols="2"
              class="mb-2 mt-2"
            > 

              <b-row>
            
                <b-col
                  cols="12"
                  class="mb-2 mt-2"
                  ><center>
                    <h4 class="mb-0">
                      Signature (Conducted By)
                    </h4>
                    </center>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col
                    cols="12"
                    class="mb-2 mt-0"
                  > 
                
                      <b-media class="mb-2 mt-2" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                        <template #aside>
                          <b-avatar
                            ref="supervisorS"
                            :src="doc_icon"
                            variant="light-info"
                            size="200px"
                            rounded
                          />
                        </template>

                        <div class="d-flex flex-wrap">
                          <input
                            ref="supervisorSInput"
                            type="file"
                            class="d-none"
                            @input="supervisorImageUpload"
                          >
                        </div>

                        
                      </b-media>


                      <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">

                    
                      <center>
                        <b-button
                            variant="danger"
                            class="mt-1 ml-2"
                            v-if="supervisorSign.image != null"
                            @click="removeSign('supervisor')"
                          >
                          <feather-icon icon="XIcon" />
                          
                        </b-button>
                      </center>


                    </b-col>
              </b-row>
            </b-col>
        </b-row> -->

        <!-- Signature component for Validated By User -->
        <!-- <SignatureComponent v-bind:title="'Signature (Validated By)'"/> -->

        <b-row>
          <b-col>
            
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="goBack()"
            >
              Cancel
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="ml-1"
              type="submit"
            >
              Submit
            </b-button>
          </b-col>


        </b-row>
        </b-form>

      </b-card>

      <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col md="3">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col md="3">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col md="3">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>

    

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox, BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import SignatureComponent from './SignatureComponent.vue';
import Bus from "../../../event-bus";

var moment = require('moment-timezone');
export default {
  components: {
    SignatureComponent, BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox, BBreadcrumb 
  },
  
  data() {
    return {
      form : {
        conducted_by:this.$store.getters.currentUser.full_name,
        designation:'',
        site:''/*this.$route.params.site_id*/,
        
      },
      sites:[],
      questions:[],
      showDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      supervisorSign : {
        image:null,
        name:'',
        type: 'supervisor',
        default:'no'
      },
      validatedBy : {
        image:null,
        name:'',
        type: 'supervisor',
        default:'no'
      },
      openedSignModel:null,
      tempSign : {
          image:null,
          name:'',
      },
      show_default:false,
      having_default:false,
      
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    filterDesignation(value){
       value = value.replace(/_/g,' ');
       value = value.toString()
       this.form.designation =  value.charAt(0).toUpperCase() + value.slice(1);

    },
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1) {
                            
                  this.form.site = this.sites[0]._id;
                  this.getQuestions();
                }

                return this.sites;
            }
        });
    },
    getQuestions(){

      this.form.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
      if (this.form.site == '') {
          
          this.questions = [];

      }else{

        return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site
           },
           api: '/api/inspection-questions-by-site'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.questions = data;
                return this.questions;
            }
        });

      }

    },

    updateCheckbox(e,index,type){
      if (e) {
        if (type == 'pass') {

          this.questions[index].fail = '';
          this.questions[index].na = ''

        }else if (type == 'fail') {
          this.questions[index].pass = '';
          this.questions[index].na = ''
        }else{
          this.questions[index].fail = '';
          this.questions[index].pass = ''
        }
      }else{
        this.questions[index].pass = '';
        this.questions[index].fail = '';
        this.questions[index].na = ''
      }
    },
    formSubmit(e){
      e.preventDefault();
      this.decisionAlert('Are you sure want to submit this report ?')
      .then(result => {
        if (result.value) {
          this.submitFunc();            
        }
      })
      
    },
    submitFunc(){
      return this.$store.dispatch(POST_API, {
           data:{
             supervisorSign:this.supervisorSign,
             validatedBy:this.validatedBy,
             questions:this.questions,
             items:this.form,
           },
           api: '/api/submit-safety-inspection'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                /*this.showDismissibleAlert = true;
                window.scrollTo(0,0);*/
                this.errorAlertCF()
            } else {
                
                var data = this.$store.getters.getResults.data;
                

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Safety Inspection Submitted Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    /*this.$router.go(-1);*/

                    this.form.conducted_by = this.$store.getters.currentUser.full_name;
                    this.filterDesignation(this.$store.getters.currentUser.role);
                    //this.form.site = ''/*this.$route.params.site_id*/;
                    this.questions = [];

                    if (this.sites.length == 1) {
                            
                      this.form.site = this.sites[0]._id;
                      this.getQuestions();
                    }else{
                      this.form.site = '';
                    }

                    /*this.sites = [];*/

                    this.supervisorSign  =  {
                      image:null,
                      name:'',
                      type: 'supervisor',
                      default:'no'
                    };

                    this.validatedBy = {
                      image:null,
                      name:'',
                      type: 'supervisor',
                      default:'no'
                    };

                    this.openedSignModel = null;

                    // emit to reset validated by signature
                    Bus.$emit('reset-signature');

                    //this.getQuestions();

                    /*var filename = data.substring(data.lastIndexOf('/')+1);
                    var link = document.createElement("a");
                    link.download = filename;
                    link.href = data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);*/
                    Bus.$emit('counter_update');
                    localStorage.setItem('routeCFCheck','no');

                    window.open(data, '_blank');

                    // this.$router.go(-1);
                    this.$router.push({ name:'custom-forms' })
                });



                //return data;
            }
        });
    },
    supervisorImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.supervisorSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.supervisorSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;
                this.supervisorSign.type =  'supervisor';
                this.supervisorSign.default = 'no';
                

            }
          }
      }
    },
    removeSign(sign){

      this.supervisorSign.image = null;
      this.supervisorSign.name = '';
      this.supervisorSign.default = 'no';
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'siganture.png',
                  }

                  this.supervisorSign = {
                    image:data.default_signature,
                    name:'signature.png',
                    type: 'supervisor',
                    default:'yes'
                  };

                  this.validatedBy = {
                    image:data.default_signature,
                    name:'signature.png',
                    type: 'supervisor',
                    default:'yes'
                  };

                  this.having_default = true;
                }
            }
        });
    },
    uploadSign(){
      
        this.$refs['supervisorSInput'].click();
      
        this.$refs['signatureModel'].hide();

    },
    openSignature(type){
      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{


        this.supervisorSign.image = data;
        this.supervisorSign.name = 'siganture.png';
        this.supervisorSign.type = 'supervisor';
        this.supervisorSign.default = 'no';

        this.$refs['signatureModel'].hide();
      }
    },
    saveDefault(){
        this.supervisorSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type :   'supervisor',
          default:'yes'
        }
        this.$refs['signatureModel'].hide();
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:null,
        text:'Quaterly Safety Inspection',
        active:true
      }];
      return item;
    },
    goBack(){
      if (this.form.site != '') {
          var msg = 'Do you want to Go back without saving your report?';

          this.decisionAlert(msg)
          .then(result => {
            if (result.value) {
              
              // this.$router.go(-1);
              this.$router.push({ name:'custom-forms' })

            }
          })
      }else{
          // this.$router.go(-1);
          this.$router.push({ name:'custom-forms' })                    
      }
    }
    
    
  },
  mounted(){
    this.useDefault();
    this.allSites();
    this.getQuestions();
    this.filterDesignation(this.$store.getters.currentUser.role);

    // to add crew sign
      Bus.$on('add-signature', (data) => {
          this.validatedBy = data.data;
      })
  }
  
  
}
</script>

<style>
  .media-aside {
      display: block !important;
      width: 100% !important;
      text-align: center !important;
  }
</style>
