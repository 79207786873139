<template>

    <div>
        <h4 class="mb-0 text-center"> {{title}} <span style="color:red">*</span></h4>

            <div class="text-center">

              <b-media class="mb-1 mt-2 text-center" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                <template #aside>
                  <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded class="text-center"/>
                </template>

                <div class="d-flex flex-wrap">
                  <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                </div>
              </b-media>

            </div>

            <div class="text-center">

              <img :src="supervisorSign.image" width="200px" height="180" class="rounded mb-1 mt-2" v-if="supervisorSign.image != null" style="background: #ffffff">
              <br>
              <b-button variant="danger" class="mt-1 ml-2 text-center" v-if="supervisorSign.image != null" @click="removeSign('supervisor')" >
                <feather-icon icon="XIcon" />
              </b-button>

            </div>
            
        <b-modal
            id="signatureModel"
            ref="signatureModel"
            centered
            title="Signature"
            no-close-on-backdrop
            hide-footer
            @hide="hideSignature"
            @show="hideSignature"
          >
          
          <div v-if="show_default == true">
            <b-row>

              <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
                <center>
                  <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
                </center>

              </b-col>
            </b-row>
            <b-row class="mt-2">

              <b-col md="6">

                  <b-button
                      variant="secondary"
                      class="mt-0 w-100"
                      @click="show_default = false"
                    >
                    <span class="text-nowrap">Add New Signature</span>
                  </b-button>
                </b-col>

                <b-col md="6">
                  <b-button
                      variant="warning"
                      class="mt-0 float-right w-100"
                      @click="saveDefault()"
                    >
                    <span class="text-nowrap">Submit</span>
                  </b-button>
                </b-col>
            </b-row>
          </div>

          <div v-else>
            <b-row>
              <b-col md="12">
                <VueSignaturePad
                  id="signature"
                  width="100%"
                  height="300px"
                  ref="signaturePad"
                  :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
                />

              </b-col>
            </b-row>
            <b-row class="mt-2">

              <b-col md="3">
                <b-button
                    variant="primary"
                    class="mt-0 w-100"
                    @click="uploadSign()"
                  >
                  <span class="text-nowrap">Upload</span>
                </b-button>
              </b-col>

              <b-col md="3">

                  <b-button
                      variant="secondary"
                      class="mt-0 w-100"
                      v-if="having_default == true"
                      @click="show_default = true"
                    >
                    <span class="text-nowrap">Default</span>
                  </b-button>
                </b-col>


              <b-col md="3">

                  <b-button
                      variant="danger"
                      class="mt-0 w-100"
                      @click="clearSignature"
                    >
                    <span class="text-nowrap">Clear</span>
                  </b-button>
                </b-col>

                <b-col md="3">
                  <b-button
                      variant="warning"
                      class="mt-0 w-100"
                      @click="saveSignature"
                    >
                    <span class="text-nowrap">Submit</span>
                  </b-button>
                </b-col>
            </b-row>
          </div>
      </b-modal>
    </div>

    <!-- <b-row>

        <b-col cols="5" class="mb-2 mt-2"> </b-col>
        <b-col cols="2" class="mb-2 mt-2">
            <b-row>
                <b-col cols="12" class="mb-2 mt-2">
                    <center>
                        <h4 class="mb-0"> {{title}} </h4>
                    </center>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" class="mb-2 mt-0"> 
                    
                    <b-media class="mb-2 mt-2" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                    
                        <template #aside>
                            <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
                        </template>

                        <div class="d-flex flex-wrap">
                            <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload">
                        </div>

                    </b-media>

                    <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">

                    <center>
                        <b-button variant="danger" class="mt-1 ml-2" v-if="supervisorSign.image != null" @click="removeSign('supervisor')" >
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </center>

                </b-col> -->


                

            <!-- </b-row>

        </b-col>

    </b-row> -->
</template>

<script>
import {
    BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile
} from 'bootstrap-vue';
import VueCropper from 'vue-cropperjs';
import Bus from "../../../event-bus";
import { GET_API, POST_API } from "../../../store/actions.type"

export default{
    name: 'SignatureComponent',
    components: {
        BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, BFormFile
    },
    props: ['title'],
    data(){
        return{
            // signature vars
                doc_icon: require('@/assets/images/doc.png'),
                supervisorSign : {
                    image   : null,
                    name    : '',
                    type    : 'supervisor',
                    default : 'no'
                },
                openedSignModel : null,
                tempSign : {
                    image:null,
                    name:'',
                },
                show_default:false,
                having_default:false,
        }
    },
    methods: {
        // signature functions
        removeSign(sign){
            this.supervisorSign.image   = null;
            this.supervisorSign.name    = '';
            this.supervisorSign.default = 'no';

            this.passSignature()
        },

        // function to pass signature data via emit
        passSignature(){
            Bus.$emit('add-signature', {data: this.supervisorSign});
        },
        useDefault(){

          return this.$store.dispatch(POST_API, {
               data:{
                 id:this.$store.getters.currentUser._id
               },
               api: '/api/get-default-signature'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    if (data == null || data.default_signature == null) {
                      
                      this.having_default = false;
                      
                    }else{

                      this.tempSign = {
                        image : data.default_signature,
                        name : 'siganture.png',
                      }

                      this.supervisorSign = {
                        image:data.default_signature,
                        name:'signature.png',
                        type: 'supervisor',
                        default:'yes'
                      };

                      this.having_default = true;
                    }
                }
            });
        },
        uploadSign(){
          
            this.$refs['supervisorSInput'].click();
          
            this.$refs['signatureModel'].hide();
            this.passSignature()

        },
        openSignature(type){
          if (this.openedSignModel == null) {

              if (this.having_default == true) {
                  this.show_default = true;
              }else{
                this.show_default = false;
              }

              this.$refs['signatureModel'].show();
              this.openedSignModel = type;

          }
        },
        hideSignature(){
          this.openedSignModel = null;
        },
        clearSignature(){
          this.$refs.signaturePad.clearSignature();
        },
        saveSignature(){
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          
          if (isEmpty) {

            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Signature is required',
              showConfirmButton: false,
              timer: 1500
            })

          }else{


            this.supervisorSign.image = data;
            this.supervisorSign.name = 'siganture.png';
            this.supervisorSign.type = 'supervisor';
            this.supervisorSign.default = 'no';

            this.$refs['signatureModel'].hide();
            this.passSignature()
          }
        },
        saveDefault(){
            this.supervisorSign = {
              image : this.tempSign.image,
              name : 'signature.png',
              type :   'supervisor',
              default:'yes'
            }
            this.$refs['signatureModel'].hide();
            this.passSignature()
        },
        supervisorImageUpload(event){
                var input = event.target;
                var files = event.target.files

                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.readAsDataURL(input.files[0]);
                    var image = input.files[0];

                    if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Please upload .png, .jpeg, .jpg images only',
                            showConfirmButton: false,
                            timer: 1500
                        });

                        this.$refs.supervisorSInput.value=null;

                    } else if (image.size > 2097152) {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Maximum 2 MB files allowed to be upload.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$refs.supervisorSInput.value=null;
                    } else {
                        reader.onload = (e) => {
                            this.supervisorSign.image = e.target.result;
                            this.supervisorSign.name = image.name;
                            this.supervisorSign.type =  'supervisor';
                            this.supervisorSign.default = 'no';
                        }
                    }
                }
                this.passSignature()
            }
    },
    mounted(){
        this.useDefault();
        Bus.$on('reset-signature', () => {
            this.supervisorSign = {
                image   : null,
                name    : '',
                type    : 'supervisor',
                default : 'no'
            };
            this.openedSignModel = null;
        })
    }
}
</script>

<style>
  .media-aside {
      display: block !important;
      width: 100% !important;
      text-align: center !important;
  }
</style>